import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, arrowRight, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, homeServices, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


const ServicesShort = () => {

  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="mt-[40px] md:mt-[90px] max-w-containerSm md:max-w-container mx-auto">
         <div className='flex flex-col justify-center items-center mb-5 md:mb-10'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">{t("Xidmətlər")}</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">{t("Ehtiyaclarınıza uyğun xidmətlər")}</p>
         </div>
   <div className='md:mt-10 md:flex justify-between'>
    <div className='md:w-[853px] '>
   <div className='md:min-h-[60px] border-b border-black mb-[16px] md:mb-[28px] flex justify-between'>
  <div className='w-1/2 md:ml-2 flex items-center md:block '>
  <p className='text-black text-lg font-medium md:mt-[12px]'>{t("Konsultasiya")}</p>
  </div>
  <div className='w-1/2 flex justify-between'>
    <p className='md:w-[386px] text-[#b3b3b3] text-sm font-normal mt-[12px] pb-2 md:pb-0'>{t("Kənd təsərrüfatında uğur və davamlı inkişaf düzgün məlumat və təcrübənin tətbiqi ilə mümkündür.")}</p>
    </div>
    <a className='flex justify-center items-center md:block' href='/services'><img className='w-8 h-8 mt-[12px]' src={arrowRight}></img></a>
   </div>
   <div className='md:h-[60px] border-b border-black mb-[16px] md:mb-[28px]  flex justify-between'>
  <div className='w-1/2 md:ml-2 flex items-center md:block '>
  <p className='text-black text-lg font-medium md:mt-[12px] '>{t("Texnikaların satışı")}</p>
  </div>
  <div className='w-1/2 flex justify-between'>
    <p className='md:w-[386px] text-[#b3b3b3] text-sm font-normal mt-[12px] pb-2 md:pb-0'>{t("Ən son innovativ satış təklif edirik.")}</p>
    </div>
    <a className='flex justify-center items-center md:block' href='/services'><img className='w-8 h-8 mt-[12px]' src={arrowRight}></img></a>
   </div>
   <div className='md:h-[60px] border-b border-black mb-[16px] md:mb-[28px] flex justify-between'>
  <div className='w-1/2 md:ml-2 flex items-center md:block '>
  <p className='text-black text-lg font-medium mt-[12px] '>{t("Satış sonrası servis")}</p>
  </div>
  <div className='w-1/2 flex justify-between'>
    <p className='md:w-[386px] text-[#b3b3b3] text-sm font-normal mt-[12px] pb-2 md:pb-0'>{t("Qüsurlu və ya aşınmış hissələrin aşkarlanması. Avadanlığın elektrik sistemlərinin diaqnostiki edilməsi.")}</p>
    </div>
    <a className='flex justify-center items-center md:block' href='/services'><img className='w-8 h-8 mt-[12px]' src={arrowRight}></img></a>
   </div>
   <div className='md:h-[60px] border-b border-black mb-[16px] md:mb-[28px] flex justify-between'>
  <div className='w-1/2 md:ml-2 flex items-center md:block '>
  <p className='text-black text-lg font-medium mt-[12px] mr-2 md:mr-0'>{t("Ehtiyat hissələrinin satışı")}</p>
  </div>
  <div className='w-1/2 flex justify-between'>
    <p className='md:w-[386px] text-[#b3b3b3] text-sm font-normal pb-2 md:pb-0'>{t("Texnikanıza və aqreqatlara uyğun orijinal ehtiyat hissələri ilə təmin edilməsi")}</p>
    </div>
    <a className='flex justify-center items-center md:block' href='/services'><img className='w-8 h-8 mt-[12px]' src={arrowRight}></img></a>
   </div>
    </div>
    <div className='md:w-[367px]  rounded-lg mt-[20px] md:mt-0'>
      <img src={homeServices}></img>
    </div>
   </div>

      </div>
  );
};

export default ServicesShort;
