import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, cardReader, dataTerminal, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const BlogsShort = () => {

  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Sərgilər");
  const buttons = [
    { key: "Sərgilər", label: "Sərgilər", type: "sergi" },
    { key: "Təlimlər", label: "Təlimlər", type: "telim" },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    // Filter blogs based on the active button
    if (activeButton) {
      const selectedType = buttons.find((btn) => btn.key === activeButton)?.type;
      if (selectedType) {
        setFilteredBlogs(blogs.filter((blog) => blog.type === selectedType).slice(0, 3));
      } else {
        setFilteredBlogs(blogs.slice(0, 3)); // Show only the first 3 if no type is matched
      }
    }
  }, [activeButton, blogs]);
  
  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/blogs.php`,
        { withCredentials: false }
      );
      const fetchedBlogs = response.data.data;
      setBlogs(fetchedBlogs);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
     <div className="mt-[40px] md:max-w-container max-w-containerSm mx-auto mb-[132px]">
      <div className='flex flex-col justify-center items-center mb-5'>
        <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">{t("Xəbərlər")}</button>
        <p className="text-black text-[22px] md:text-[32px] font-medium text-center">{t("Ən son xəbərlər")}</p>
      </div>
      <div className='flex gap-2 mt-10'>
        {buttons.map((button) => (
          <button
            key={button.key}
            onClick={() => setActiveButton(button.key)}
            className={`h-[36px] rounded-[20px] px-2 mt-2 md:mt-0 transition-all duration-300 ease-in-out ${
              activeButton === button.key
                ? "text-[white] bg-[#0D4B2C]"
                : "text-[white] bg-[#b0d896] hover:bg-[#0D4B2C] hover:text-[white]"
            }`}
          >
            {t(button.label)}
          </button>
        ))}
      </div>
      <div>
        <div className='grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-4 mt-10'>
          {filteredBlogs.map((val, index) => {
            const { id, image1, title, text } = val;
            // Limit the text to 100 characters
            const truncatedTitle = t(title).length > 30 ? `${t(title).slice(0, 30)}...` : t(title);
            const truncatedText = t(text).length > 120 ? `${t(text).slice(0, 120)}...` : t(text);

            return (
              <a href={`/news/${id}`} key={index}>
                <div data-aos="fade-up" className="bg-[#f5f9ee] rounded-[20px] md:h-[406px] pt-1">
                  <div className="mt-3 mb-5 ml-4 mr-4">
                    <div className="md:w-[354px] h-[197px] rounded-lg overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={`https://www.agrogurama.az/agrogurama/${image1}`}
                        alt={title}
                      />
                    </div>
                    <div className="text mt-5">
                      <h4 className="text-black text-base md:text-xl font-medium mb-2">{t(truncatedTitle)}</h4>
                      <h4 className="text-[#b7b7b7] text-[14px] md:text-base font-normal mb-2">{truncatedText}</h4>
                      <button className="rounded-[14px] border border-[#0d4b2c] w-36 h-10 mb-[12px] hover:bg-[#0d4b2c] hover:text-[white] transition duration-300 ease-in-out">
                        {t("Daha ətraflı")}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
        <div className='mt-5 mx-auto flex justify-center'>
                    <a href='/news'><p className='text-black text-base font-normal inline-block border-b border-black'>{t("Hamısını gör")}</p></a>
                </div>
      </div>
    </div>
  );
};

export default BlogsShort;

