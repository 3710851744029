import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { about1, about2, about3, hero, kataloq1, values1, values2, values3, values4 } from "../../assets";

export default function About() {

  const { t, i18n } = useTranslation();
  return (
    <>
       <div className="md:max-w-container max-w-containerSm mt-[40px] mx-auto mb-[40px] md:mb-[132px]">
               <div className='flex flex-col justify-center items-center'>
         <button className="w-[120px] bg-[#d4d64f] rounded-[32px] text-[white]">{t("Haqqımızda")}</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">{t("Kənd Təsərrüfatı Sektorunda Artan Trend")}</p>
         </div>
  <div className="flex flex-col md:flex-row justify-center items-center md:mt-[40px] mt-[20px] ">
   <div className="md:w-1/2 rounded-[14px] "><img className="rounded-[12px] md:rounded-[12px] mb-[20px] md:mb-[0px]" src={about1}></img></div>
<div className="md:w-1/2  md:pl-[32px]">
<p className="text-[#0d4b2c] text-[18px] md:text-[32px] font-medium mb-[16px] md:mb-[22px]">{t("Biz Kimik")}</p>
<p className="text-black text-sm font-normal">{t("aboutUs")}</p>
</div>
   </div>
   <div className="flex flex-col-reverse md:flex-row justify-center items-center md:mt-[40px] mt-[20px]">
<div className="md:w-1/2  md:pr-[32px]">
<p className="text-black text-sm font-normal">{t("aboutUs2")}</p>
</div>
<div className="md:w-1/2 mb-[20px] md:mb-[0px]"><img src={about2}></img></div>
   </div>
   <div className="flex flex-col md:flex-row  justify-center items-center mt-[20px] md:mt-[40px]">
   <div className="md:w-1/2"><img className="rounded-[12px] md:rounded-[12px] mb-[20px] md:mb-[0px]" src={about3}></img></div>
<div className="md:w-1/2  md:pl-[32px]">
<p className="text-[#0d4b2c] text-[18px] md:text-[32px] font-medium mb-5">{t("Dəyərlərimiz")}</p>
<p className=" text-black text-sm font-normal">
<div className="flex md:h-20 mb-4 md:mb-0">
<img className="h-[40px] w-[40px]" src={values1}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-base md:text-lg font-medium">{t("Etibarlılıq")}</p>
<p className="text-black text-sm font-normal">{t("Müştərilərimizə etibarlı və uzun ömürlü məhsul təmin edərək, onların iş proseslərini dəstəkləmək.")}</p>
</div>
 </div>
 <div className="flex md:h-20 mb-4 md:mb-0">
 <img className="h-[40px] w-[40px]" src={values2}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">{t("Keyfiyyət")}</p>
<p className="text-black text-sm font-normal">{t("Yüksək keyfiyyətli aqreqat məhsulları təqdim edərək müştəri tələblərinə ən yaxşı şəkildə cavab vermək.")}</p>
</div>
 </div>
 <div className="flex md:h-20 mb-4 md:mb-0">
 <img className="h-[40px] w-[40px]" src={values3}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">{t("İnnovasiya")}</p>
<p className="text-black text-sm font-normal">{t("Texnoloji inkişafları izləyərək, kənd təsərrüfatı sektorunda ən müasir həlləri təqdim etmək.")}</p>
</div>
 </div>
 <div className="flex md:h-20">
 <img className="h-[40px] w-[40px]" src={values4}></img>
<div className="flex flex-col ml-3">
<p className="text-black text-lg font-medium">{t("Müştəri məmnuniyyəti")}</p>
<p className="text-black text-sm font-normal">{t("Müştəri ehtiyaclarını diqqətlə dinləyərək, fərdi yanaşma ilə xidmət göstərmək.")}</p>
</div>
 </div>
</p>
</div>
   </div>
     </div>
    </>
  );
}
