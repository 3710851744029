import React from 'react'
import Header from '../../Components/Header/Header'
import { certificate1, certificate2, certificate3, leaf, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, whyus, whyus2 } from '../../assets/index'
import { useTranslation } from 'react-i18next';


const Partners = () => {

    const { t, i18n } = useTranslation();
  return (
    <>
    <div className="mx-auto pt-[40px] md:pb-[158px] pb-[40px] ">
       <div style={{
    backgroundImage: `url(${leaf})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Dikeyde ortalar
    alignItems: 'center', // Yatayda ortalar
    textAlign: 'center',
    borderRadius:'20px',
    zIndex:'20'
  }} className="md:max-w-container max-w-containerSm mx-auto md:h-[893px]">
       <div className='flex flex-col justify-center items-center md:mb-10 mb-4'>
         <button className="px-4 bg-[#d4d64f] rounded-[32px] text-[white]">{t("Distribyutor")}</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">{t("Əməkdaşlıq etdiyimiz şirkətlər")}</p>
         </div>
    <div className='text-[black] h-auto lg:h-[960px] mb-[40px] lg:mb-[283px]'>
      <div className='max-w-container lg: mx-auto'>
      <div className='md:h-[145px] grid md:grid-cols-4 grid-cols-1 md:gap-8 gap-4'>
      <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px]'>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners1} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners2} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners3} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners4} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners5} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners6} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners7} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners8} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%]'>
         <img className='h-[113px] object-contain' src={partners10} alt="Partner 1" />
         </div>
         </div>
         <div className=' w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] '>
         <div className='w-[90%] mx-auto'>
         <img className='h-[113px] object-contain mx-auto' src={partners11} alt="Partner 1" />
         </div>
         </div>
        </div>
      </div>
    </div>
     </div>
     
</div>
  
    
    </>
  )
}

export default Partners  